import {Injectable} from '@angular/core';
import {PlatformService} from './platform-service/platform.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {

    private ticketingTestTechnicalLinkUuid = '4021c277-1949-420a-a353-0aad5f59fee4';

    constructor(private platformService: PlatformService) {
    }

    public isTicketingTestOrganisationActive = (): boolean => {
        return [this.ticketingTestTechnicalLinkUuid].includes(this.platformService.getTechnicalLinkId());
    }
}
