import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {
    private account: any;
    private technicalLinkId: string;

    constructor(private http: HttpClient) {
    }

    setAccount(account: any): void {
        this.account = account;
    }

    setTechnicalLinkId(technicalLinkId: any): void {
        this.technicalLinkId = technicalLinkId;
    }

    getAccount(): any {
        if (!this.account) {
            this.account = {
                ID: environment.ACCOUNT_GUID
            };
        }

        return this.account;
    }

    getTechnicalLinkId(): string {
        if (!environment.production && localStorage.getItem('seatedDashboard.technicalLinkId')) {
            return localStorage.getItem('seatedDashboard.technicalLinkId');
        }

        return this.technicalLinkId;
    }

    getPersons() {
        const config = {
            withCredentials: true,
            params: new HttpParams().set('accountID', this.getAccount().ID)
        };

        return this.http.get<any>(environment.CM_API_PROXY_URL + `/usermanagement/v1.0/memberships`, config);
    }

    /** Capital keys are to be consistent with the Platform Headers getCmAccount function */
    public returnMockAccount(): any {
        return {
            ID: environment.ACCOUNT_GUID,
            TechnicalLinks: [{
                TechnicalLinkTypeID: 'TICKETING',
                Keys: {
                    Guid: '4021C277-1949-420A-A353-0AAD5F59FEE4'
                }
            }]
        };
    }

    public getTicketingTechnicalLinkId(technicalLinks): string {
        return technicalLinks.find(technicalLink => technicalLink.TechnicalLinkTypeID === 'TICKETING')?.ID;
    }
}
