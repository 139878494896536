import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagService} from '../../shared/services/feature-flag.service';

@Directive({
    selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit {
    @Input() featureFlag = true;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private featureFlagService: FeatureFlagService) {
    }

    ngOnInit() {
        if (!this.featureFlag || this.featureFlagService.isTicketingTestOrganisationActive()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
