import {Pipe, PipeTransform} from '@angular/core';
import {CmTranslationService} from '../services/cm-translation.service';
import {environment} from '../../../environments/environment';

@Pipe({
    name: 'trans'
})

export class TranslationPipe implements PipeTransform {
    constructor(private translate: CmTranslationService) {
    }

    transform(key: string = ''): string {
        const translation = this.translate.getPhraseForLanguage(key);
        if (translation) {
            return translation;
        }

        if (environment.production) {
            return '';
        }

        if (environment.ENVIRONMENT === 'local') {
            return `{{${key}}}`;
        }

        return key;
    }
}
